/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo } from 'react';
import {
  ImageList, ImageListItem, CardActionArea, Box, Grid,
} from '@mui/material';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import Typography from '@mui/material/Typography';
import {
  ChromePicker, ColorResult, RGBColor, SketchPicker,
} from 'react-color';
import { StageData } from '../../../redux/currentStageData';
import useItem from '../../../hook/useItem';
import { StyledTab, StyledTabs, setTabId } from './StyledPanel';
import TabPanel from '../../tab/TabPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import borderList from '../../../config/borders.json';
import RWColorPicker from '../../colorPicker/RWColorPicker';

export interface PanelItemProps {
  onStageChange: (changeName: string) => void
}

export default function FramePanel({ onStageChange }: PanelItemProps) {
  const colorToRGB = (rgb: string): { red: number, green: number, blue: number, alpha: number } => {
    const result = rgb.length > 0 ? rgb.replace(/[^\d,.]/g, '').split(',') : null;

    return result ? {
      red: parseInt(result[0], 10),
      green: parseInt(result[1], 10),
      blue: parseInt(result[2], 10),
      alpha: parseFloat(result[3] ?? '1.0'),
    } : {
      red: 0,
      green: 0,
      blue: 0,
      alpha: 0,
    };
  };

  const {
    createItem, removeItem, updateItem, stageData,
  } = useItem();
  const targetItemBorder = useMemo(
    () => stageData.find((data) => data.id === 'border' || data.attrs.id === 'border'),
    [stageData],
  );

  const [color, setColor] = React.useState<RGBColor>(() => (targetItemBorder?.attrs?.color ? targetItemBorder.attrs.color : {
    r: 255, g: 255, b: 255, a: 1.0,
  }));

  const handleColorChange = (color: any) => {
    setColor(color.rgb);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorder) {
      const {
        r, b, g, a,
      } = color.rgb;

      onStageChange('Updated Border Color');
      updateItem(targetItemBorder.id, (prevData) => ({
        ...targetItemBorder.attrs,
        red: r,
        green: g,
        blue: b,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setColor(color.rgb);
    }
  };

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    let imageSource = `${process.env.PUBLIC_URL}/images/borders/${data.imageSrc}`;
    const {
      r, b, g, a,
    } = color;
    removeItem('border');
    removeItem('border-background');
    imageSrc.onload = () => {
      const width = data.imageWidth;
      const height = data.imageHeight;
      const newBorder: StageData = {
        id: 'border',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - width) / 2,
          y: (canvasHeight - height) / 2,
          width,
          height,
          red: r,
          green: g,
          blue: b,
          opacity: a,
          src: imageSource,
          zIndex: 10,
          draggable: false,
          brightness: 0,
          color: { color },
          filters: ['RGB'],
          updatedAt: Date.now(),
        },
      };

      imageSource = `${process.env.PUBLIC_URL}/images/masks/${data.maskSrc}`;
      const newBackground: StageData = {
        id: 'border-background',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - data.maskWidth) / 2,
          y: (canvasHeight - data.maskHeight) / 2,
          width: data.maskWidth,
          height: data.maskHeight,
          red: 255,
          green: 255,
          blue: 255,
          src: imageSource,
          zIndex: 0,
          draggable: false,
          brightness: 0,
          color: { color },
          filters: ['RGB', 'ClipMask'],
          updatedAt: Date.now(),
        },
      };

      onStageChange('Updated Border');
      createItem(newBorder);
      onStageChange('Updated Border Background');
      createItem(newBackground);
    };

    imageSrc.src = `${process.env.PUBLIC_URL}/images/borders/${data.imageSrc}`;
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange} sx={{ mt: 1 }}>
        <StyledTab label="Border" {...setTabId(0)} />
        <StyledTab label="Styles" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container>
          <Grid item xs={12} display="flex" flexWrap="wrap">
            <ImageList cols={2} sx={{ my: 0, maxHeight: 480 }}>
              <CardActionArea onClick={() => { removeItem('border'); removeItem('border-background'); }}>
                <ImageListItem>
                  <Typography textAlign="center">None</Typography>
                </ImageListItem>
              </CardActionArea>
              {borderList.map((item, index) => (
                <CardActionArea key={index} onClick={() => { insertImage(item); }}>
                  <ImageListItem key={index}>
                    <img
                      key={index}
                      src={`${process.env.PUBLIC_URL}/images/borders/${item.imageSrc}`}
                      srcSet={`${process.env.PUBLIC_URL}/images/borders/${item.imageSrc}`}
                      alt={item.id}
                      loading="lazy"
                    />
                  </ImageListItem>
                </CardActionArea>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <Grid container mt={2}>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <RWColorPicker color={color} onChange={handleColorChange} onChangeComplete={handleColorChangeCompleted} />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </>
  );
}
