/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Checkbox, FormControlLabel, Grid, Link,
} from '@mui/material';
import LoginBox from '../auth/LoginBox';
import { useAuth } from '../../hook/use-auth';
import { fetchUpsertUserConfig } from '../../generated/realmWorldsApiComponents';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  width: '70%',
  p: 4,
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
  onConfirm: () => void
};

export default function WelcomeModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const auth = useAuth();
  const handleClose = () => { onClose?.(new Event('close'), 'escapeKeyDown'); setOpen(false); };
  const [dissmissWelcome, setDissmissWelcome] = React.useState(false);

  const handleDissmissWelcome = (checked: boolean) => {
    setDissmissWelcome(checked);
    fetchUpsertUserConfig({
      body: {
        autoDismissIntroScreen: checked,
      },
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => { if (auth?.user != null) { handleClose(); } }}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, pointerEvents: auth && auth?.user != null ? 'none' : 'initial' }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={auth?.user == null ? 6 : 12}>
              <Typography variant="h2" mb={1} fontWeight={400} align={auth?.user == null ? 'left' : 'center'}>Welcome 🎊</Typography>
              <Typography mb={3} align={auth?.user == null ? 'left' : 'center'}>
                Thanks for using Realm Worlds Token Tool!
                Our tool is focused on providing
                the community of VTT hobbyist the opportunity
                to explore more options to VTT token crafting.
              </Typography>
              <Typography mb={3} align={auth?.user == null ? 'left' : 'center'}>
                *NOTE:
                <br />
                You cannot save or connect to
                our extension unless you are signed in!
              </Typography>
              <Typography mt={8} mb={3} align={auth?.user == null ? 'left' : 'center'}>
                -Azaris ( Forever DM ) - CEO
              </Typography>
              <Grid container justifyContent={auth?.user == null ? 'left' : 'center'} mb={4} sx={{ pointerEvents: 'initial' }}>
                <Grid item mr={1}>
                  <Button href="https://realmworlds.net/" sx={{ minWidth: 0, padding: 0 }} target="_blank">
                    <Box width={66} component="img" src="./images/rw_logo.png" />
                  </Button>
                </Grid>
                <Grid item mr={1}>
                  <Button href="https://www.reddit.com/r/RealmWorlds" sx={{ minWidth: 0, padding: 0 }} target="_blank">
                    <Box width={66} component="img" src="./images/reddit_icon.png" />
                  </Button>
                </Grid>
                <Grid item mr={1}>
                  <Button href="https://discord.gg/pndajRXRRg" sx={{ minWidth: 0, padding: 0 }} target="_blank">
                    <Box width={66} component="img" src="./images/discord_icon.png" />
                  </Button>
                </Grid>
              </Grid>
              <Link color="#E923B1" fontWeight={400} fontSize={24} sx={{ pointerEvents: 'initial' }}><Typography fontWeight={400} fontSize={16} mt={2} align={auth?.user == null ? 'left' : 'center'}>Get our Free Extension here!</Typography></Link>
              {auth?.user != null
                ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography fontWeight={400} fontSize={50} mt={2} align="center" color="rgba(255, 255, 255, 0.5)">
                      Click Anywhere to Continue
                    </Typography>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          value=""
                          color="primary"
                          onChange={(e) => handleDissmissWelcome(e.target.checked)}
                        />
                      )}
                      value={dissmissWelcome}
                      sx={{ pointerEvents: 'initial' }}
                      label={(
                        <p>
                          Do not show this message again
                        </p>
                      )}
                    />
                  </Box>
                ) : null }
              <Typography fontWeight={400} fontSize={16} mt={2} align={auth?.user == null ? 'left' : 'center'}>
                Realmworlds LLC
                {` ${new Date().getFullYear()}`}
              </Typography>
            </Grid>
            {auth && auth?.user == null
                            && (
                            <>
                              <Grid item xs={6}>
                                <LoginBox onLogin={() => handleClose()} />
                              </Grid>
                              <Grid item xs={12} mt={8}>
                                <Link width="100%" color="rgba(255, 255, 255, 0.5)" component="button" align="center" onClick={() => handleClose()}>
                                  Continue using Realm Worlds Token Tool without
                                  signing in
                                </Link>
                                <Typography align="center" color="rgba(255, 255, 255, 0.5)" fontSize={12}>
                                  By clicking you agree to Realm Worlds
                                  LLC
                                  {' '}
                                  <Link color="rgba(255, 255, 255, 0.5)">Term & Conditions</Link>
                                </Typography>
                              </Grid>
                            </>
                            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
