/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  styled,
  Theme,
  CSSObject,
  Collapse,
  Toolbar,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import PanelItem from './panels/PanelItem';
import { useAuth } from '../../hook/use-auth';

const drawerWidth = 65;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   padding: theme.spacing(0, 1),
//   minHeight: 47,
//   height: 47,
//   // necessary for content to be below app bar
//   // ...theme.mixins.toolbar,
// }));

// const SlideHint = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.primary.light,
//   content: "''",
//   display: 'block',
//   position: 'relative',
//   borderRadius: 15,
//   width: 36,
//   height: 124,
//   left: 0,
//   top: '50%',
// }));

export default function Panel({ onStageChange }: { onStageChange: (changeName: string) => void }) {
  const auth = useAuth();
  const [opened, setOpened] = useState(false);
  const handleChange = () => {
    setOpened((prev) => !prev);
  };
  const [drawerOpened] = useState(false);

  const [activeTab, setActiveTab] = useState('none');

  const setNewPanel = (value: string) => {
    setOpened((prev) => {
      let opened = true;

      setActiveTab((prevTab) => {
        if (prevTab === value) {
          opened = !prev;
        }

        return value;
      });
      return opened;
    });
  };

  return (
    <>
      <Drawer variant="permanent" open={drawerOpened} PaperProps={{ sx: { backgroundColor: 'transparent', borderRight: 'none' } }} sx={{ backgroundColor: 'blue', position: 'absolute', pointerEvents: 'none' }}>
        <Toolbar variant="dense" />
        <List sx={{
          pointerEvents: 'auto',
          backgroundColor: '#181818',
          mx: 1,
          mt: 1,
          borderRadius: '10px',
          borderRight: 'none',
        }}
        >
          <ListItem key="TokenFrame" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: drawerOpened ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mx: 'auto',
                  justifyContent: 'center',
                }}
                onClick={() => setNewPanel('frame')}
              >
                <FontAwesomeIcon icon={regular('circle')} size="lg" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem key="Background" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minWidth: 0,
                mx: 'auto',
                justifyContent: 'center',
              }}
              onClick={() => {
                setNewPanel('background');
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mx: 'auto',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon icon={solid('square')} size="lg" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem key="Overlay" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: drawerOpened ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => setNewPanel('overlay')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mx: 'auto',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon icon={regular('square')} size="lg" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem key="Text" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minWidth: 0,
                mx: 'auto',
                justifyContent: 'center',
              }}
              onClick={() => {
                setNewPanel('text');
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mx: 'auto',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon icon={solid('text-height')} size="lg" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem key="Notes" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              disabled={!auth?.user}
              sx={{
                minWidth: 0,
                mx: 'auto',
                justifyContent: 'center',
              }}
              onClick={() => {
                setNewPanel('notes');
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mx: 'auto',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon icon={solid('scroll')} size="lg" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box sx={{ display: 'flex', flexDirection: 'row', pointerEvents: 'none' }}>
        <Collapse
          sx={{
            height: '100%', position: 'absolute', zIndex: '10', left: 65,
          }}
          in={opened}
          orientation="horizontal"
        >
          <PanelItem activeTab={activeTab} onStageChange={onStageChange} />
        </Collapse>
        {/* <Slide
          direction="right"
          in={opened}
          container={drawerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <PanelItem activeTab={activeTab} />
        </Slide> */}

        <Box sx={{ height: '100%', width: 0, display: 'none' }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid item>
              <IconButton onClick={handleChange} style={{}}>
                {opened ? <FontAwesomeIcon icon={solid('chevron-left')} /> : <FontAwesomeIcon icon={solid('chevron-right')} />}
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
