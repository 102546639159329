import * as React from 'react';
import { Box, Toolbar } from '@mui/material';
import BackgroundPanel from './BackgroundPanel';
import FramePanel from './FramePanel';
import NotesPanel from './NotesPanel';
import TextPanel from './TextPanel';
import OverlayPanel from './OverlayPanel';

interface PanelProps {
  activeTab: string,
  onStageChange: (changeName: string) => void
}

export default function PanelItem({ activeTab, onStageChange }: PanelProps) {
  return (
    <Box sx={{ height: '100%', width: 250, pointerEvents: 'none' }}>
      <Toolbar variant="dense" />
      <Box sx={{ pointerEvents: 'auto' }}>
        {activeTab === 'frame' && <FramePanel onStageChange={onStageChange} />}
        {activeTab === 'background' && <BackgroundPanel onStageChange={onStageChange} />}
        {activeTab === 'overlay' && <OverlayPanel onStageChange={onStageChange} />}
        {activeTab === 'text' && <TextPanel onStageChange={onStageChange} />}
        {activeTab === 'notes' && <NotesPanel />}
      </Box>
    </Box>
  );
}
